.dzsap-sticktobottom.dzsap-sticktobottom-for-skin-wave {
  bottom: 0;
  opacity: 1;
}
.dzsap-sticktobottom {
  background-color: red;
}
/* .waveform-container {
  height: 40px;
} */
#waveform ::part(cursor) {
  height: 80px;
  top: 28px;
  border-radius: 4px;
  border: 1px solid #fff;
}
#waveform .canvases canvas {
  height: 40px;
}
