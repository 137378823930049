.wrapper {
  width: 80%;
  margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: unset;
  margin: 0;
}
body {
  font-family: "mont-regular";
}
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  display: none;
}
html {
  scroll-behavior: smooth;
}
html.modal-enabled {
  overflow: hidden !important;
}
:root {
  --blue: rgb(14, 45, 94);
  --red: rgb(255, 73, 69);
}
@font-face {
  font-family: "mont-light";
  src: url(../fonts/Mont/Mont-Light.woff);
}
@font-face {
  font-family: "mont-bold";
  src: url(../fonts/Mont/Mont-Bold.woff);
}
@font-face {
  font-family: "mont-bolder";
  src: url(../fonts/Mont/Mont-Heavy.woff);
}
@font-face {
  font-family: "mont-regular";
  src: url(../fonts/Mont/Mont-Regular.woff);
}
@font-face {
  font-family: "mont-medium";
  src: url(../fonts/Mont/Mont-SemiBold.woff);
}
:root {
  --blue: #0e2d5e;
  --red: #ff4945;
  --grey: #adadad;
}
@keyframes curtainRaise {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes curtainLower {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}
img {
  display: block;
  width: 100%;
}
@keyframes waves {
  0% {
    -webkit-transform: scale(0.2, 0.2);
    transform: scale(0.2, 0.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    transform: scale(0.9, 0.9);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.fa-play:before {
  content: "\f04b";
}
.video-play-button {
  position: relative;
  z-index: 1;
}
.waves {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #f0aeac;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  border-radius: 100%;
  right: -12px;
  bottom: -12px;
  z-index: -1;
  -webkit-animation: waves 3s ease-in-out infinite;
  animation: waves 3s ease-in-out infinite;
}

.wave-1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.wave-2 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.wave-3 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.reactmodal {
  margin-top: 100px !important;
}
/* reactmodal responsive */
@media all and (max-width: 480px) {
  .reactmodal {
    width: 360px;
  }
  .wrapper {
    width: 90% !important;
  }
}

/* slick button */
.slick-prev:before,
.slick-next:before {
  font-size: 20px !important;
  line-height: 1;
  padding: 20px !important;
  opacity: 0.75;
  color: rgb(213, 11, 0) !important;
}
.slick-prev {
  left: -50px !important;
}
